$white: #ffffff;
$black: #000000;

$primary: #00ffa7;
$primary90: #1affb0;
$primary80: #33ffb9;
$primary70: #4dffc1;
$primary60: #66ffca;
$primary50: #80ffd3;
$primary40: #99ffdc;
$primary30: #b2ffe4;
$primary20: #ccffed;
$primary10: #e5fff6;

$secondary100: #8f00ff;
$secondary90: #9a1aff;
$secondary80: #a03cee;
$secondary70: #a652e7;
$secondary60: #ba71f3;
$secondary50: #be8be5;
$secondary40: #d1acee;
$secondary30: #dec0f4;
$secondary20: #f1e2fc;
$secondary10: #e5fff6;

$tertiary: #4829a3;
$tertiary90: #5a3eac;
$tertiary80: #6d54b5;
$tertiary70: #7f69be;
$tertiary60: #917ec8;
$tertiary50: #a394d1;
$tertiary40: #b6a9da;
$tertiary30: #c2eefb;
$tertiary20: #dad4ed;
$tertiary10: #edeaf6;

$darkShades: #191f28;
$darkShades90: #2d3644;
$darkShades80: #3c4859;
$darkShades70: #4b586f;
$darkShades60: #63718b;
$darkShades50: #707f9a;
$darkShades40: #8894aa;
$darkShades30: #94a1b8;
$darkShades20: #abb8cf;
$darkShades10: #c3cee1;

$greyShades: #b0b8c5;
$greyShades90: #b7bfca;
$greyShades80: #bec5d0;
$greyShades70: #c5ccd6;
$greyShades60: #ccd2db;
$greyShades50: #d3d9e0;
$greyShades40: #dbe0e6;
$greyShades30: #e7eaee;
$greyShades20: #eff1f3;
$greyShades10: #f7f8f9;

$chartColor101: #cdffee;
$chartColor102: #00ffa7;
$chartColor103: #24d9d9;
$chartColor104: #a4bf00;
$chartColor105: #ffb800;
$chartColor106: #ff8a00;
$chartColor107: #ee5843;
$chartColor108: #f51a41;
$chartColor109: #f9748c;
$chartColor110: #ffacbb;
$chartColor111: #db78d1;
$chartColor112: #e7a5ff;
$chartColor113: #8819cc;
$chartColor114: #6819cc;
$chartColor115: #5a00ff;
$chartColor116: #4968d4;
$chartColor117: #0085ff;
$chartColor118: #6597c6;

$typographyWhite: #ffffff;
$typographyPrimary: $primary;
$typographySecondary: $secondary100;
$typographyTertiary: $tertiary;
$typographyGrey: #b0b8c5;
$typographyDisabled: rgba(255, 255, 255, 0.28);
$typographyHover: #87ffd5;
$typographyDark: #252d3a;

$buttonPrimaryDefault: $primary;
$buttonPrimaryHover: #87ffd5;
$buttonPrimaryPressed: $primary;
$buttonPrimaryDisabled: rgba(0, 255, 167, 0.5);

$buttonWhiteDefault: #ffffff;
$buttonWhiteHover: #edf1f6;
$buttonWhitePressed: #dbe0e6;
$buttonWhiteDisabled: rgba(0, 255, 167, 0.5);

$statusSuccess: $primary;
$statusInfo: $secondary100;
$statusWarning: #ffdd7d;
$statusError: #fd4b50;
$statusPending: rgba(66, 76, 93, 0.38);
$statusInProgress: #fba1a1;
$statusReviewed: $secondary100;
$statusCompleted: $primary;
$inProgressStatus: #FFC700;

$label1: #00ffa7;
$label2: #24d9d9;
$label3: #a4bf00;
$label4: #edeaad;
$label5: #ee5843;
$label6: #fba1a1;
$label7: #8f00ff;
$label8: #e8a5ff;
$label9: #497ed4;
$label10: #8cb0eb;
$label11: #5a00ff;
$label12: #9d67ff;

$iconDefault: #c5d0de;
$iconWhite: #ffffff;
$iconBlack: #364153;
$iconColorGreen: $primary;
$iconSecondary: $secondary100;

$border: #424c5d;
// $borderDefault:#C5CDD9;
$borderLight: #707986;
$borderGreen: #00ffa7;
$borderRed: #fd4b50;
$borderDisabled: rgba(66, 76, 93, 0.38);
$borderSecondary: $secondary100;

$backgroundDark: #191f28;
$backgroundSecondary: #535d6d;
$backgroundTertiary:#161b24;
$background-paper: #252d3a;
$backgroundCard: #161b24;
$text-secondary: #b0b8c5;
$cardHover: #2e3847;
$tablebgcolor: #344055;
$success: #00ffa7;
$inprogress: #26bedf;
$tagBgColor: #1d2734;

$dicomActive: #6c03fe;
$violet:#b14dff;
$modalBorder: #00ffa7;

$tableHeadBg: #10141c;

$viewerSelectBox: #111822;

.CardBg {
    background: $backgroundCard;
    border-radius: 25px;
    box-shadow: 7px 35px 63px 0px rgba(0, 0, 0, 0.4);
    border: 1px solid #272c35;
}

.ControlBtnBg {
    border-radius: 22px;
    border: 1px solid $border;
    background: $background-paper;
}

.flex-center-all {
    display: flex;
    justify-content: center;
    align-items: center;
}
